import { http } from '@/core/api/';

const apiUrl = 'api/pei/academicprogresscalendar';

export const getNextAcademicYearsBySie = async (academicYearId, sieId) => {
  return await http.get(
    `${apiUrl}/nextacademicyearsbysie/${academicYearId}/${sieId}`
  );
};

export const getAdditionalProgressInformation = async (
  academicYearId,
  sieId
) => {
  return await http.get(
    `${apiUrl}/additionalprogressinformation/${academicYearId}/${sieId}`
  );
};

export default {
  getNextAcademicYearsBySie,
  getAdditionalProgressInformation,
};
