var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.studentSieId)?_c('div',{attrs:{"id":"academic-progress-inform"}},[_c('page-header',{attrs:{"has-return":"","page":{
      icon: 'fas fa-user-edit',
      title: 'Informe de Progreso Académico',
      routes: ['Inicio', 'Informe de Progreso Académico'],
    }}}),_c('div',[_c('MatchMedia',[_c('b-row',{staticClass:"mb-4"},[_c('b-col',{attrs:{"cols":"12","md":"7"}}),_c('b-col',{attrs:{"cols":"12","md":"5"}},[_c('b-card',{staticClass:"bg-gray border-0"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"8"}},[_c('v-select',{staticClass:"w-100 mr-3",attrs:{"placeholder":"Año Academico","label":"name","clearable":true,"searchable":true,"options":_vm.filter.academicYear.options},model:{value:(_vm.filter.academicYear.selected),callback:function ($$v) {_vm.$set(_vm.filter.academicYear, "selected", $$v)},expression:"filter.academicYear.selected"}})],1),_c('b-col',{staticClass:"mt-4 mt-md-0",attrs:{"cols":"12","md":"4"}},[_c('b-button',{attrs:{"block":"","variant":"success"},on:{"click":_vm.onSearch}},[_c('em',{staticClass:"fas fa-search fa-fw"}),_vm._v(" Buscar ")])],1)],1)],1)],1)],1)],1)],1),(_vm.results.length === 0)?_c('div',{staticClass:"mt-5 pt-3"},[_c('custom-result-not-found')],1):_c('div',[_c('custom-separator',{staticClass:"my-4",attrs:{"text":"Resultados"}}),_vm._l((_vm.results),function(row,idx){return _c('result-row',{key:("result-" + idx),staticClass:"mb-3",attrs:{"item":row},on:{"on-open-close":function($event){return _vm.openCloseResultHandler($event, idx)},"download":_vm.onDownloadHandler},scopedSlots:_vm._u([{key:"amends",fn:function(ref){
    var items = ref.items;
return [_c('view-amends',{attrs:{"items":items},on:{"print-amend":function (ref) {
        var id = ref.id;

        return _vm.onDownloadHandler(id);
    }}})]}}],null,true)})})],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }