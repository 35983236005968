<template>
  <div>
    <callout
      class="custom-callout"
      apply-border-top
      has-gradient
      controlled
      has-border
      :has-toggle="isExpanded"
      :opened="isExpanded"
      @click:open="isExpanded = !isExpanded"
    >
      <div class="row py-2">
        <div
          class="col-12 col-md d-flex align-items-center border-right mr-0 mr-md-5"
        >
          <p class="font-18 font-weight-bold mb-0">{{ item.weekName }}</p>
        </div>
        <div class="col-12 col-md-7">
          <div class="row">
            <div class="col-12 col-md">
              <label class="text-gray font-14">Estatus:</label>
              <p class="mb-0">
                <i class="fas fa-circle font-12 mr-2" :class="status.color"></i>
                <span class="font-14">{{ status.text }}</span>
              </p>
            </div>
            <div class="col-12 col-md">
              <label class="text-gray font-14">Progreso Académico:</label>
              <p class="font-14 mb-0">
                {{ (deliveryDateHavePassed ? percentage : null) | empty }}
              </p>
            </div>
            <div class="col-12 col-md">
              <label class="text-gray font-14">Fecha de Sometido:</label>
              <p class="font-14 mb-0">{{ (deliveryDateHavePassed ? item.submittedDate : null) | date }}</p>
            </div>
            <div class="col-12 col-md">
              <label class="text-gray font-14">Fecha de Discusion:</label>
              <p class="font-14 mb-0">{{ item.discussionDate | date }}</p>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-3 d-flex justify-content-end">
          <DropDown
            class="actions-dropdown pt-2 pr-4"
            :items="getMenuItems"
            normal-size
            :always-show-dropdown="true"
          />
        </div>
      </div>
      <template #edition>
        <slot name="amends" :items="item.amends"></slot>
      </template>
    </callout>
    <div
      v-if="!deliveryDateHavePassed"
      class="alert alert-warning border-top-0"
      role="alert"
    >
      <i class="fas fa-bell mr-2"></i>
      El Informe de Progreso Académico correspondiente a la
      {{ item.weekName.toLocaleLowerCase() }} se entregará el
      {{ deliveryDate.format('LL') }}.
    </div>
  </div>
</template>

<script>
import Callout from 'custom-callout';
import DropDown from 'dropdown';
import { pieAcademicProgress } from '@/utils/constants/pei/academic-progress-inform/pieAcademicProgress';
import moment from 'moment';
import { date } from '@/utils/filters';

export default {
  name: 'AcademicProgressInformResultRow',
  filters: {
    date,
  },
  components: {
    Callout,
    DropDown,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    isExpanded: false,
    actionType: null,
    currentAcademicProgressId: null,
  }),
  computed: {
    getMenuItems() {
      let result = [];
      const statuses = [
        pieAcademicProgress.Submitted,
        pieAcademicProgress.Discussed,
      ];
      if (
        this.deliveryDateHavePassed &&
        statuses.includes(this.item.statusId)
      ) {
        result.push({
          label: `Imprimir Informe de Progreso`,
          icon: 'fas fa-print text-green',
          click: () => this.$emit('download', this.item.academicProgressId),
        });
      }
      if (this.deliveryDateHavePassed && this.item.amends.length > 0) {
        result.push({
          label: `Ver Enmiendas`,
          icon: 'fas fa-clock-rotate-left text-green',
          click: this.viewAmend,
        });
      }
      return result;
    },
    status() {
      switch (this.item.statusId) {
        case pieAcademicProgress.Pending:
          return {
            color: 'circle-pending',
            text: 'Pendiente',
          };
        case pieAcademicProgress.Submitted:
          return {
            color: 'circle-submitted',
            text: 'Sometido',
          };
        case pieAcademicProgress.Discussed:
          return {
            color: 'circle-discussed',
            text: 'Discutido',
          };
        default:
          return {
            color: 'circle-unavailable',
            text: 'No Disponible',
          };
      }
    },
    percentage() {
      return this.item.progress ? `${this.item.progress}%` : null;
    },
    deliveryDate() {
      if (!this.item.deliveryStrDate) return null;
      return moment(this.item.deliveryStrDate, 'YYYY/MM/DD');
    },
    deliveryDateHavePassed() {
      return this.deliveryDate?.isBefore(moment());
    },
  },
  methods: {
    viewAmend() {
      this.isExpanded = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/_colors.scss';

.custom-callout ::v-deep > div {
  border-left-color: #b5c3bc !important;
}
.week {
  color: map-get($colors, 'gray-12');
}

.circle-unavailable {
  color: map-get($colors, 'secondary-15');
}
.circle-pending {
  color: map-get($colors, 'pink');
}
.circle-submitted {
  color: #fae76f;
}
.circle-discussed {
  color: map-get($colors, 'celadon');
}
.alert.alert-warning.border-top-0 {
  border: 2px solid;
  font: normal normal normal 16px/24px Lato;
  color: #af6708;
  border-color: #fdac41;
}
::v-deep .fa-clock-rotate-left:before {
  content: '\f1da';
}
</style>
