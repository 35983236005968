export const ids = Object.freeze({
  Y2023_2024: 27,
});

export const names = Object.freeze({
  27: '2023-2024',
});

export default {
  ids,
  names,
};
