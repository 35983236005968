<template>
  <div class="bg-secondary-3">
    <div class="py-1 px-4 rounded">
      <div class="row mt-4">
        <div class="col-12 d-flex">
          <i class="fas fa-clock-rotate-left text-orange mr-2 mt-1"></i>
          <p class="callout-sub-section-title">Ver Enmiendas</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <callout
            v-for="(item, index) in items"
            :key="index"
            class="custom-callout"
            apply-border-top
            has-gradient
            controlled
            has-border
            :has-toggle="false"
            :opened="false"
            :class-main="'bg-white mb-3'"
          >
            <div class="row">
              <div class="col-12 col-md-9">
                <div class="row pt-2">
                  <div class="col-12 col-md-3">
                    <label class="text-gray font-14">Fecha de Enmienda:</label>
                    <p class="font-14 text-description">
                      {{ item.date | date }}
                    </p>
                  </div>
                  <div class="col">
                    <label class="text-gray font-14">Progreso Académico:</label>
                    <p class="font-14 text-description">
                      {{ percentage(item) | empty }}
                    </p>
                  </div>
                  <div class="col">
                    <label class="text-gray font-14">Fecha de Sometido:</label>
                    <p class="font-14 text-description">
                      {{ item.submittedDate | date }}
                    </p>
                  </div>
                  <div class="col">
                    <label class="text-gray font-14">Fecha de Discusión:</label>
                    <p class="font-14 text-description">
                      {{ item.discussionDate | date }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-11">
                    <label class="text-gray font-14">
                      Comentario de la enmienda:
                    </label>
                    <p class="font-14 text-description">{{ item.reason }}</p>
                  </div>
                </div>
              </div>
              <div class="col d-flex align-items-start justify-content-end">
                <div class="p-2">
                  <action-button
                    class="ml-2 px-4 mb-3 actionButton"
                    type="button"
                    text="Imprimir"
                    i="fas fa-print"
                    variant="outline-success"
                    @click="printAmend(item)"
                  />
                </div>
              </div>
            </div>
          </callout>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionButton from 'ActionButton';
import Callout from 'custom-callout';
import { date } from '@/utils/filters';
export default {
  name: 'AcademicProgressViewAmend',
  filters: {
    date,
  },
  components: {
    ActionButton,
    Callout,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
      require: true,
    },
  },
  computed: {
    percentage() {
      return (item) => `${item.progress}%`;
    },
  },
  methods: {
    async printAmend(amend) {
      this.$emit('print-amend', amend);
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  font-weight: normal !important;
}
.custom-callout ::v-deep > div {
  border-left-color: #b5c3bc !important;
}
.callout-sub-section-title {
  color: #3f5b60;
  font-weight: 600;
}
::v-deep .fa-clock-rotate-left:before {
  content: '\f1da';
}
</style>
