<template>
  <div v-if="studentSieId" id="academic-progress-inform">
    <page-header
      has-return
      :page="{
        icon: 'fas fa-user-edit',
        title: 'Informe de Progreso Académico',
        routes: ['Inicio', 'Informe de Progreso Académico'],
      }"
    />
    <div>
      <MatchMedia>
        <b-row class="mb-4">
          <b-col cols="12" md="7" />
          <b-col cols="12" md="5">
            <b-card class="bg-gray border-0">
              <b-row>
                <b-col cols="12" md="8">
                  <v-select
                    placeholder="Año Academico"
                    class="w-100 mr-3"
                    label="name"
                    :clearable="true"
                    :searchable="true"
                    v-model="filter.academicYear.selected"
                    :options="filter.academicYear.options"
                  />
                </b-col>
                <b-col class="mt-4 mt-md-0" cols="12" md="4">
                  <b-button block variant="success" @click="onSearch">
                    <em class="fas fa-search fa-fw" />
                    Buscar
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </MatchMedia>
    </div>

    <div v-if="results.length === 0" class="mt-5 pt-3">
      <custom-result-not-found />
    </div>
    <div v-else>
      <custom-separator class="my-4" text="Resultados" />
      <result-row
        v-for="(row, idx) of results"
        :key="`result-${idx}`"
        class="mb-3"
        :item="row"
        @on-open-close="openCloseResultHandler($event, idx)"
        @download="onDownloadHandler"
      >
        <template #amends="{ items }">
          <view-amends
            :items="items"
            @print-amend="({ id }) => onDownloadHandler(id)"
          ></view-amends>
        </template>
      </result-row>
    </div>
  </div>
</template>

<script>
import { MatchMedia } from 'vue-component-media-queries';
import PageHeader from 'PageHeader';
import CustomSeparator from 'custom-separator';
import CustomResultNotFound from 'custom-result-not-found';
import ResultRow from './components/AcademicProgressInformResultRow';
import ViewAmends from './components/AcademicProgressViewAmend.vue';
import {
  getNextAcademicYearsBySie,
  getAdditionalProgressInformation,
} from '@/services/pei/academicProgressCalendar';
import { ids as academicYearIds } from '@/enums/academic-years';
import reportService from '@/services/report';

export default {
  name: 'AcademicProgressInform',
  components: {
    PageHeader,
    MatchMedia,
    CustomSeparator,
    CustomResultNotFound,
    ResultRow,
    ViewAmends,
  },
  props: { studentSieId: { type: [String, Number] } },
  data() {
    return {
      filter: { academicYear: { selected: null, options: [] } },
      results: [],
    };
  },
  mounted() {
    this.getNextAcademicYears();
  },
  methods: {
    async onSearch() {
      await this.getProgressInformation();
    },
    async getNextAcademicYears() {
      const { data } = await getNextAcademicYearsBySie(
        academicYearIds.Y2023_2024,
        this.studentSieId
      );
      this.filter.academicYear.options = data;
      this.filter.academicYear.selected = data[0];
      this.getProgressInformation();
    },
    async getProgressInformation() {
      const { id: academicYearId } = this.filter.academicYear.selected;
      const { data } = await getAdditionalProgressInformation(
        academicYearId,
        this.studentSieId
      );
      this.results = data;
    },
    async onDownloadHandler(id) {
      await reportService.downloadStudentsByProgressAcademicYearReport(id);
    },
  },
};
</script>

<style lang="scss" scoped>
#academic-progress-inform > div ::v-deep {
  .vs__dropdown-toggle {
    background: white !important;
  }

  #dropdownMenuButton {
    background: transparent;
  }
}
</style>
